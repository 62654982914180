import { 
  ReactElement, 
  useEffect, 
  useRef,
  useState 
} from "react";

import dayjs, { Dayjs } from "dayjs";
import { 
  getDownloadURL, 
  ref, 
  uploadBytes } from "firebase/storage";
import { MuiFileInput } from "mui-file-input";
import { 
  useNavigate 
} from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SaveIcon from "@mui/icons-material/Save";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material-next/Button";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";


import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";
import { sectionStyles } from "../styles/styles";
import Competition from "../types/Competition";
import Event from "../types/Event";
import EventDay from "../types/EventDay";
import League from "../types/League";
import { 
  CombineDateJSTimeJS,
  FromDayJS, 
  ToDayJS 
} from "../utils/utils";

import CompetitionEditor from "./CompetitionEditor";
import EventDateCalendar from "./EventDateCalendar";
import EventTimeEditor from "./EventTimeEditor";

export interface EventEditorProps {
  event : Event;
  isNewEvent : boolean;
  onCancel?: () => void;
  onSubmit?: (event: Event) => void;
}

export default function EventEditor(props: EventEditorProps ): ReactElement {
  const { isNewEvent, onCancel, onSubmit } = props;

  const {
    imagesRef,
    isRevealingGodsSecrets,
  } = useAuth();

  const {
    deleteEvent,
    isLeagueAdmin,
    isOrgAdmin,
    leagues,
  } = useData();



  const navigate = useNavigate();
  
  const [event, setEvent] = useState<Event>(props.event);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [eventLogoUrl, setEventLogoUrl] = useState("");
  const [uploadLogo, setUploadLogo] = useState<File | null>(null);
  const [defaultTime, setDefaultTime] = useState<Dayjs>(
    (props.event && props.event.days.length > 0 && props.event.days[0].datetime) ? 
    ToDayJS(props.event.days[0].datetime, props.event.timezone) : dayjs("2000-01-01T09:00:00")
  )
  const defaultCourseId = useRef(
    (props.event && props.event.days.length > 0 && props.event.days[0].courseId) ? 
    props.event.days[0].courseId : ""
  );

  useEffect(() => {
    if (event && event.logo) {
      getDownloadURL(ref(imagesRef, event.logo)).then((url) => {
        setEventLogoUrl(url);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [imagesRef, event, event?.logo]);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
      console.log("No cancel handler provided.");
    }
  }

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(event);
    } else {
      console.log("No submit handler provided.");
    }
  };

  //Todo: Write the logic to move events to seasons.
  const handleCopyToSeason = () => {
    if (event) {
      console.log("Will eventually use this to migrate events to seasons.")
      // copyEventToSeason(event);
    };
  };

  const handleImageChange = (newFile: File | null) => {
    if (newFile) {
      setUploadLogo(newFile);
      uploadFile(newFile);
    }
  };

  const handleNameChange = (target: EventTarget) => {
    const name = (target as HTMLInputElement).value;
    if (event) {
      setEvent({ ...event, name: name});
    }
  }

  const uploadFile = function (newLogo: File) {
    const logoName = newLogo.name;
    const logoRef = ref(imagesRef, logoName);
    uploadBytes(logoRef, newLogo).then((/* snapshot */) => {
      if (event) {
        setEvent({ ...event, logo: logoName });
        console.log("Uploaded a blob or file!");
      }
    });
  };

  const deleteThisEvent = (id: string) => {
    console.log(`Deleting event ${id}`);
    deleteEvent(id);
    setDeleteConfirm(false);
    navigate("/");
  };

  const handleEventDateChange = (dates: Dayjs[] | null) => {
    if (event) {
      if (!dates || dates.length === 0) {
        setEvent({ ...event, datetime: FromDayJS(null), days: [] });
      } else if (event.days.length === 0) { 
        setEvent({ 
          ...event, 
          datetime: FromDayJS(dates[0]), 
          days: dates.map(date => ({ 
            datetime: CombineDateJSTimeJS(date, defaultTime), 
            courseId: defaultCourseId.current, 
            duration: 60, 
            isGolf: event.isGolf 
          })) 
        });
      } else {
        const newDays = dates.map((date, i) => {
          const existingDay = event.days.find(day => ToDayJS(day.datetime).isSame(date, "D"));
          if (existingDay) {
            return existingDay;
          } else {
            return { 
              datetime: CombineDateJSTimeJS(date, defaultTime), 
              courseId: defaultCourseId.current, 
              duration: 60, 
              isGolf: event.isGolf };
          }
        });
        setEvent({ ...event, datetime: newDays[0].datetime, days: newDays });
      }
    }
  }

  const handleEventTimesChange = (newDays: EventDay [] | null) => {
    if (event) {
      if (newDays) {
        setEvent({ ...event, datetime: newDays[0].datetime, days: newDays });
        setDefaultTime(ToDayJS(newDays[0].datetime, props.event.timezone));
      }
    }
  }

  const handleCompetitionChange = (competition: Partial<Competition>) => {
    if (event) {
      const competitions = event.competitions;
    }
  }


  function Competitions(): ReactElement {
    if (!event || !event.competitions) {
      return <Button>Add Competition</Button>;
    } else {
      return (
        <Stack spacing={2}>
          {event.competitions.map((competition) => (
            <CompetitionEditor key={competition.itemId} competition={competition} onChange={handleCompetitionChange}/>
          ))}
        </Stack>
      );
    }
  }

  if (event) {
    return (
      <Box sx={sectionStyles}>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  aria-label="name"
                  aria-required="true"
                  value ={event.name}
                  onChange={({ target }) =>
                    handleNameChange(target)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              { isOrgAdmin(event.orgId) && leagues && leagues.length > 0 && 
                <TextField
                  id="league"
                  autoFocus={true}
                  required
                  fullWidth
                  select
                  label="League"
                  aria-label="League"
                  aria-required="true"
                  value={event.leagueId}
                  onChange={({ target }) => {
                    let leagueName = "";
                    const matchingLeague = leagues?.find(league => league.itemId === target.value);
                    if (matchingLeague) {
                      leagueName = matchingLeague.name;
                    }
                    setEvent({ ...event, leagueId: target.value, leagueName: leagueName })
                  }}
                >
                  {leagues && leagues.map((league: League) => (
                    <MenuItem key={league.itemId} value={league.itemId}>
                      {league.name}
                    </MenuItem>
                  ))}
                </TextField> 
              }
              { !isOrgAdmin(event.orgId) && isLeagueAdmin(event.leagueId) &&
                <TextField
                  id="league-disabled"
                  autoFocus={true}
                  label="League"
                  aria-label="League"
                  value={event.leagueName}
                  disabled
                />
              }
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="description"
                  label="Description"
                  aria-label="description"
                  aria-required="false"
                  fullWidth
                  value={event.description}
                  onChange={({ target }) =>
                    setEvent({ ...event, description: target.value })
                  }
                />
              </Grid>
              <Grid item container xs={12} sm={6}>
                <Grid item justifyContent="center" xs={2}>
                  <Avatar
                    alt="brand logo"
                    id={"eventLogo"}
                    src={eventLogoUrl ? eventLogoUrl : ""}
                  />
                </Grid>
                <Grid item xs={10}>
                  <MuiFileInput
                    fullWidth
                    label="Event Logo"
                    aria-label="event logo"
                    arai-required="false"
                    value={uploadLogo}
                    onChange={handleImageChange}
                  />
                </Grid>
              </Grid>
            
            <Grid container direction="row" justifyContent="flexStart">
              <Grid container item alignContent="center" justifyContent="center" xs={3}>
                <FormGroup>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item></Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={event.isGolf}
                              onChange={(e) => {
                                if (e != null) {
                                  setEvent({
                                    ...event,
                                    isGolf: e.target.checked,
                                  });
                                }
                              }}
                              name="checkedA"
                            />
                          }
                          label={event.isGolf ? "Golf" : "Social"}
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </FormGroup>
              </Grid>
            </Grid>

            </Grid>
            <Grid container sx={{justifyContent: "center"}}>
              <Grid item xs={12} md={6} >
                <EventDateCalendar 
                  onChange={handleEventDateChange}
                  orientation="portrait"
                  value={event.days.map(day => ToDayJS(day.datetime, event.timezone))}
                />
              </Grid>
              <Grid item xs={12} md={6} width="100%">
                <EventTimeEditor 
                  days={event.days}
                  defaultTime={defaultTime}
                  isGolfEvent={event.isGolf}
                  onChange={handleEventTimesChange}
                  timezone={event.timezone} 
                />
              </Grid>
            </Grid>
            <DateTimePicker
              label="Sign Up Begins at"
              aria-label="sign up opens at"
              aria-required="true"
              value={ToDayJS(event.signupOpensAt, event.timezone)}
              onChange={(target) => {
                if (target != null) {
                  setEvent({
                    ...event,
                    signupOpensAt: FromDayJS(target),
                  });
                  if (FromDayJS(target) > event.signupClosesAt) {
                    setEvent({
                      ...event,
                      signupClosesAt: FromDayJS(target),
                    });
                  }
                }
              }}
            />
            <DateTimePicker
              label="Sign Up Deadline"
              aria-label="sign up closes at"
              aria-required="true"
              value={ToDayJS(event.signupClosesAt, event.timezone)}
              onChange={(target) => {
                if (target != null) {
                  setEvent({
                    ...event,
                    signupClosesAt: FromDayJS(target),
                  });
                }
              }}
            />

            {event && !event.isGolf && (
              <TextField
                id="socialLocation"
                autoFocus={false}
                label="Social Location"
                arial-label="Location of social event"
                aria-required="false"
                value={event.socialLocation}
                onChange={({ target }) =>
                  setEvent({ ...event, socialLocation: target.value })
                }
              />
            )}
            {event && event.isGolf && (
              <Grid container spacing={2}>
                <Grid item xs={4} md={3}>
                  <TextField
                    id="numteetimes"
                    label="Tee Times"
                    aria-label="Number of Tee Times"
                    aria-required="true"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    value={event.numTeeTimes}
                    onChange={({ target }) => {
                      const numTeeTimes = parseInt(target.value !== "" ? target.value : "0", 10);
                      setEvent({
                        ...event,
                        numTeeTimes: numTeeTimes,
                        maxPlayers: numTeeTimes * 4
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={3}>
                  <TextField
                    id="maxPlayers"
                    label="# of Golfers"
                    aria-label="Max number of golfers"
                    aria-required="false"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    value={event.maxPlayers}
                    onChange={({ target }) =>
                      setEvent({
                        ...event,
                        maxPlayers: parseInt(target.value, 10),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={isRevealingGodsSecrets ? 4 : 6} md={2}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="top"
                      aria-label="member member"
                      aria-required="false"
                      control={
                        <Checkbox
                          checked={event.isMemberMember}
                          name="Member Member"
                          onChange={({ target }) =>
                            setEvent({ ...event, isMemberMember: target.checked })
                          }
                        />
                      }
                      label="Member/Member?"
                      labelPlacement="top"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={isRevealingGodsSecrets ? 4 : 6} md={2}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="top"
                      aria-label="member guest"
                      aria-required="false"
                      control={
                        <Checkbox
                          checked={event.isMemberGuest}
                          name="Member Guest"
                          onChange={({ target }) =>
                            setEvent({ ...event, isMemberGuest: target.checked })
                          }
                        />
                      }
                      label="Member/Guest?"
                      labelPlacement="top"
                    />
                  </FormControl>
                </Grid>
                {isRevealingGodsSecrets && 
                  <Grid item xs={4} md={2}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="top"
                        aria-label="is a test event"
                        aria-required="false"
                        control={
                          <Checkbox
                            checked={event.isTest}
                            name="Test Event"
                            onChange={({ target }) =>
                              setEvent({ ...event, isTest: target.checked })
                            }
                          />
                        }
                        label="Test?"
                        labelPlacement="top"
                      />
                    </FormControl>
                  </Grid>
                }
              </Grid>
            )}
            <Stack spacing={2}>
              <Competitions />
            </Stack>

            <Stack direction="row" spacing={2} >
              <Button 
                type="submit" 
                key="formSubmit" 
                size="medium" 
                variant="filled" 
                color="primary" 
                startIcon={<SaveIcon />}>
                  {isNewEvent ? "Create Event" : "Update Event"}
              </Button>
              <Button 
                size="medium" 
                variant="outlined" 
                color="secondary" 
                startIcon={<CancelIcon />} 
                onClick={handleCancel}>
                Cancel
              </Button>
              {isOrgAdmin(event.orgId) && 
                <>
                  <Button type="button" key="formDelete" variant="outlined" color="secondary" aria-label="delete" size="medium"
                    onClick={() => setDeleteConfirm(true)}startIcon={<DeleteIcon />}>
                    Delete Event
                  </Button>
                  {deleteConfirm && (
                    <span>
                      <Button type="button" key="deleteConfirm" size="medium" onClick={() => deleteThisEvent(event.itemId)}>
                        Confirm
                      </Button>
                      <Button type="button" key="deleteCancel" size="medium" onClick={() => setDeleteConfirm(false)}>
                        Cancel
                      </Button>
                    </span>
                  )} 
                  {isRevealingGodsSecrets &&                 
                    <Button type="button" key="formMove" variant="outlined" aria-label="move" size="large"
                      onClick={() => handleCopyToSeason()}startIcon={<MoveDownIcon />}>
                      Copy to Season 2023
                    </Button>
                  }
                </>
                }
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
    } else {
      return (<Container />);
    }
}
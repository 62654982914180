import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { 
  getDownloadURL, 
  ref
} from "firebase/storage";
import { 
  useNavigate
} from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import DustbinIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SaveIcon from "@mui/icons-material/Save";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material-next/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";
import { 
  actionStyles, 
  sectionStyles,
  subtitleStyles
} from "../styles/styles";
import UserProfile from "../types/UserProfile";
import { FromDayJS, ToDayJS } from "../utils/utils";

interface PersonEditorProps {
  profile: UserProfile,
  isNewProfile: boolean,
  onCancel?: () => void,
  onSubmit?: (profile: UserProfile, profilePic: File | null | undefined) => void
}

export default function PersonEditor(props: PersonEditorProps): ReactElement {
  const { isNewProfile, onCancel, onSubmit } = props;
  const { isRevealingGodsSecrets, imagesRef } = useAuth();
  const { adminOrgs, isOrgAdmin, updateMembership, userProfile } = useData();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState("");
  const [uploadPic, setUploadPic] = useState<File | null | undefined>(undefined);
  const [profile, setProfile] = useState<UserProfile>(props.profile);

  useEffect (() => {
    if (profile && profile.pictureName) {
      getDownloadURL(ref(imagesRef, profile.picture)).then((url) => {
        setProfilePic(url);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [imagesRef, profile]);

  const handleImageChange = (newFile: File | null | undefined) => {
    if (profile) {
      if (newFile) {
        console.log("newFile: ", newFile)
        const picture = profile.itemId;
        setUploadPic(newFile);
        setProfilePic(URL.createObjectURL(newFile));
        setProfile({...profile, picture: picture, pictureName: newFile.name}) 
      // Image is being removed
      } else if (newFile === null) {
        setUploadPic(null);
        setProfilePic("");
        setProfile({...profile, picture: "", pictureName: ""})
      }
    }
  };

  const handleMembershipChange = () => {
    if (profile) {
      updateMembership({itemId: profile.itemId, isActive: true, isMember: true, isAdmin: false})
      setProfile({...profile, isActive: !profile.isActive})
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(profile, uploadPic);
    } else {
      console.log("No submit handler provided.");
    }
  };

  if (profile) {
    // You can only edit your own profile unless you are an admin
    if ((profile.itemId === userProfile?.itemId) || (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0]))) {
      return (
        <Box 
          component="form"
          sx={sectionStyles}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <Grid container spacing={{ xs: 8, md: 12 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                autoFocus={true}
                fullWidth
                required
                label="Name"
                aria-label="Your name"
                aria-required="true"
                defaultValue={profile.name}
                onChange={(e) => setProfile({...profile, name: e.target.value })}
              />
            </Grid>      
            <Grid item xs={2} sm={1} md={1}>
              <Avatar
                alt="profile picture"
                id={"profilePic"}
                src={profilePic ? profilePic : ""}
              />
            </Grid>
            <Grid item xs={10} sm={5} md={5}>
              <Button variant="outlined" color="secondary" startIcon={<FileUploadIcon />} component="label">
                {profile.picture ? "Change" : "Add"} Photo
                <input type="file" hidden accept="image/*" onChange={(e) => handleImageChange(e.target.files?.item(0))} />
              </Button>
              {profile.picture && <Button variant="text" color="tertiary" startIcon={<DustbinIcon />} onClick={() => handleImageChange(null)}>
                Remove Photo
              </Button>}
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                id="ghin"
                fullWidth
                type="number"
                label="GHIN"
                aria-label="Golf Handicap Index Number"
                aria-required="true"
                inputProps={{ maxLength: 7 }}
                defaultValue={profile.ghin}
                onChange={(e) => setProfile({...profile, ghin: (+e.target.value).toString()})}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="index"
                fullWidth
                type="number"
                label="index (from GHIN)"
                disabled
                InputProps={{ inputProps: { min: 0, max: 45, step:.1, readOnly: true }}}
                aria-label="GHIN index"
                aria-required="true"
                defaultValue={profile.index}
                onChange={(e) => setProfile({...profile, index: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Birthday"
                aria-label="Birthday"
                aria-required="true"
                defaultValue={ToDayJS(profile.birthday)}
                onChange={(e) => {e && setProfile({...profile, birthday: FromDayJS(e)})}}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="gender-radio-buttons-group">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="gender-radio-buttons-group"
                    name="gender-radio-buttons-group"
                    defaultValue={profile.gender}
                    onChange={(e) => setProfile({...profile, gender: e.target.value})} 
                  >
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" gutterBottom sx={subtitleStyles}>
                Contact Information
              </Typography>
            </Grid> 
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                required
                fullWidth
                label="Email"
                aria-label="Email address"
                aria-required="true"
                defaultValue={profile.email}
                onChange={(e) => setProfile({...profile, email: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="phone"
                required
                fullWidth
                label="Phone"
                aria-label="Phone number"
                aria-required="true"
                defaultValue={profile.phone}
                onChange={(e) => setProfile({...profile, phone: e.target.value})}
              /> 
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="mailingAddress"
                fullWidth
                label="Mailing Address"
                aria-label="Mailing address"
                defaultValue={profile.mailingAddress}
                onChange={(e) => setProfile({...profile, mailingAddress: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="localAddress"
                fullWidth
                label="Local Address"
                aria-label="Local address"
                defaultValue={profile.localAddress}
                onChange={(e) => setProfile({...profile, localAddress: e.target.value })}
              />  
            </Grid>  
            { isRevealingGodsSecrets &&
              <Grid item xs={4}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    id="isTest"
                    value="top"
                    aria-label="Is a test profile"
                    aria-required="false"
                    control={
                      <Checkbox
                        checked={profile?.isTest}
                        name="Test Event"
                        onChange={(e) => setProfile({...profile, isTest: e.target.checked })}
                      />
                    }
                    label="Test?"
                    labelPlacement="top"
                  />
                </FormControl>
              </Grid>
            } 
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={actionStyles}> 
                <FormGroup>
                  <FormControlLabel
                    control={<Switch 
                      id={"actions" + profile.itemId} 
                      checked={profile.isActive} 
                      color="secondary" 
                      onChange={ handleMembershipChange } 
                    />}
                    label="Active"
                  />
                </FormGroup>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Button size="medium" variant="filled" color="primary" startIcon={<SaveIcon />} type="submit">
                  {isNewProfile ? "Create" : "Update"}
                </Button>
                <Button 
                  size="medium" 
                  variant="outlined" 
                  color="secondary" 
                  startIcon={<CancelIcon />} 
                  onClick={handleCancel}>
                  Cancel
                </Button>
              </Stack>
            </Grid>  
          </Grid>    
        </Box>
      );
    } else {
      // todo: Add a message that you can't edit this profile
      navigate(-1)
      return <></>;
    }
  } else {
    return <></>;
  }
}
import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { 
  doc, 
  DocumentReference, 
  Timestamp 
} from "firebase/firestore";
import { 
  getDownloadURL, 
  ref 
} from "firebase/storage";
import { useDocumentData } from 'react-firebase-hooks/firestore';

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { useData } from "../DataProvider";
import { 
  firestore, 
  imagesRef 
} from "../firebase";
import { cardStyles } from "../styles/styles";
import Event from "../types/Event";
import Registration from "../types/Registration";
import { 
  CurrentDateTime, 
  DisplayDate, 
  DisplayDates, 
  DisplayDateTime, 
  DisplayTime 
} from "../utils/utils";

export default function EventHeader(props: { event: Event }): ReactElement {
  const event = props.event;
  const {
    courses,
    leagues,
    userProfile,
  } = useData();

  // TODO: Handle userProfile not loaded.
  const [registration, isRegistrationLoading, isRegistrationLoadingError] = 
    useDocumentData<Registration>(doc(firestore, "event/" + event.itemId + "/reg/" + userProfile?.itemId) as DocumentReference<Registration>);

  const [eventLogo, setEventLogo] = useState("");
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    if (locationName) {
      console.log("LocationName: " + locationName)
    }
  }, [locationName]);

  useEffect(() => {
    const generateLocationName = (event: Event): string => {
      if (event.isGolf) {
        if (event.courseIds) {
          if (event.courseIds.length > 1) {
            if (!event.courseIds.every( (val, i, arr) => val === arr[0])) {
              return "Multiple Courses";
            } else {
              return courses ? courses.find(c => c.itemId === event.courseIds?.[0])?.name ?? "" : "";
            }
          } else {
            return courses ? courses.find(c => c.itemId === event.courseIds?.[0])?.name ?? "" : "";
          }
        } else {
          return courses ? courses.find(c => c.itemId === event.courseId)?.name ?? "" : "";
        }
      } else {
        return event.socialLocation;
      }
    }

    if (event) {
      if (event.logo) {
        getDownloadURL(ref(imagesRef, event.logo)).then((url) => {
          setEventLogo(url);
        }).catch((error) => {
          console.log(error);
        });
      }
      console.log("event: ", event.name, "logo: ", event.logo)
      if (courses)  {
        setLocationName(generateLocationName(event));
      }
    }
  }, [courses, event, event?.logo]);

  useEffect(() => {
    //if (registration) {
    //  console.log("registration: " + registration.isRegistered);
    //}
    if (isRegistrationLoading) {
      console.log("Loading registration");
    }
    if (isRegistrationLoadingError) {
        console.log("Error loading registration");
    }

  }, [registration, isRegistrationLoading, isRegistrationLoadingError]);
  
  const isSignupOpen = () => {
    if (event) {
      const currentDateTime = CurrentDateTime();
      return (currentDateTime > event.signupOpensAt && currentDateTime < event.signupClosesAt);
    } else {
      return false;
    }
  }

  const statusText = () => {
    const currentDateTime = CurrentDateTime();
    if (event) {
      if (isSignupOpen()) {
        return (
          "Signup closes at " + 
          DisplayDate(event.signupClosesAt.toDate(), event.timezone, "MMMM D") + ", " + 
          DisplayTime(event.signupClosesAt, event.timezone, 'h:mmA')
        );
      } else if (currentDateTime < event.signupOpensAt) {
        return ("Signups open at " + DisplayDateTime(event.signupOpensAt, event.timezone));
      } else if (currentDateTime > event.datetime) {
        return ("event has passed");
      } else {
        return ("Signup is closed.");
      }
    } else {
      return ("");
    }
  }

  const StyledLeague = (): ReactElement => {
    if (leagues) {
      return (
        <Box sx={cardStyles.underLine}>
          <Typography align="right" sx={cardStyles.tagline}>
            {event.leagueName + (event.seasonName ? " (" + event.seasonName + ")" : "")}
          </Typography>
        </Box>
      );
    } else {
      return (<></>);
    }

  }

  const StyledDate = (): ReactElement => {
    if (event) {
      return (
        <Typography component="span" sx={cardStyles.title}>
          {event.datetimes ? 
            DisplayDates(event.datetimes.map(datetime => datetime.toDate()), event.timezone) :
            DisplayDate(event.datetime.toDate(), event.timezone)}
        </Typography>
      )
    } else {
      return (<></>);
    }
  }

  const StyledTime = (): ReactElement => {
    if (event) {
      if (event.isGolf) {  
        if (event.datetimes) {
          let dayNum = -1;
          return (
            <Box>
              {event.datetimes.flatMap((datetime: Timestamp) => {
                dayNum++;
                const course = courses ? courses.find(c => c.itemId === event.courseIds?.[dayNum]) : undefined;
                const locationName = course ? course.shortName : "";
                const tz = course ? course.timezone : "America/Los_Angeles";
                return (
                  <Typography sx={cardStyles.footer} key={dayNum}>
                    { event?.isShotguns?.[dayNum] ?
                      locationName + ": " + DisplayDate(datetime.toDate(), tz, "MMM DD, ") + DisplayTime(datetime, tz) + " shotgun start" :
                      locationName + ": " + DisplayDate(datetime.toDate(), tz, "MMM DD ") + DisplayTime(datetime, tz) + " " + event.numTeeTimes + " tee times" }
                  </Typography>
                )
              })}
            </Box>);        
        } else {
          return (
            <Typography sx={cardStyles.footer}>
              { event.isShotgun ?
                  "Shotgun start at " + DisplayTime(event.datetime, "America/Los_Angeles") :
                  event.numTeeTimes + " tee times starting at " + DisplayTime(event.datetime, "America/Los_Angeles")}
            </Typography>
          );
        }
      } else {
        return (
          <Typography sx={cardStyles.footer}>
            {DisplayTime(event.datetime, event.timezone) + " - " + DisplayTime(event.endTime, event.timezone)}
          </Typography>
        )
      }
    } else {
      return (<></>);
    }
  }

  if (event) {
    return (
    <>
      <Box>
        <Box sx={cardStyles.overline}>
          { eventLogo && 
            <Avatar sx={cardStyles.avatar} alt='brand logo' id={"eventLogo"} src={eventLogo} />}
          <Typography sx={cardStyles.header}>{statusText()}</Typography>   
        </Box>    
        <Divider sx={cardStyles.divider}/>
        <StyledLeague />
      </Box>
      <Box sx={{p: 4}}>
        <Typography component="span" sx={cardStyles.title}>
          {StyledDate()}
        </Typography>
        {event.name.length > 0 &&
        <Typography component="span" sx={cardStyles.subtitle}>
          {" - " + event.name}
        </Typography>}
        {locationName && 
        <Typography sx={cardStyles.location}>
          {locationName}
        </Typography>}
        <Typography sx={cardStyles.description}>
          {event.description}
        </Typography>
        <StyledTime />
      </Box>
    </>);
  } else {
    return (<Box />);
  }
}

import { 
  ReactElement,
  useEffect,
  useRef,
  useState
} from  "react";

import { Dayjs } from "dayjs";

import Box from "@mui/material/Box";  
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import {
  PickersDay
} from "@mui/x-date-pickers";
import {
  DatePickerToolbar,
  DatePickerToolbarProps
} from '@mui/x-date-pickers/DatePicker';
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import { 
  subtitleStyles 
} from "../styles/styles";
import { DisplayDates } from "../utils/utils";

export interface EventDateCalendarProps {
  currentDate?: Dayjs;
  value?: Dayjs [];
  defaultValue?: Dayjs [];
  onChange?: (newDates: Dayjs [] | null) => void;
  orientation?: "portrait" | "landscape";
}

export default function EventDateCalendar (props: EventDateCalendarProps): ReactElement {
  const onChange = props.onChange;

  const [pickedDates, setPickedDates] = useState<Dayjs []>([]);
  const [isMultipleDates, setIsMultipleDates] = useState<boolean>(props.value ? props.value.length > 1 : false);
  const refDates = useRef<Dayjs []>(props.value ? props.value : []);
  

  useEffect(() => {
    setPickedDates(refDates.current ? refDates.current : []);
  }, []);

  useEffect(() => {
    if (!isMultipleDates) {
      if (refDates.current.length > 1) {
        const firstDate = refDates.current.reduce((acc, current) => current.valueOf() < acc.valueOf() ? current : acc, refDates.current[0] || undefined);
        refDates.current = [firstDate];
        setPickedDates(refDates.current);
      }
    }
  }, [isMultipleDates]);

  useEffect(() => {
    console.log("Picked Dates: " + pickedDates);
    if (onChange) {
      onChange(pickedDates);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedDates]);

  function isSameDay(a: Dayjs, b: Dayjs) {
    return (
      a.year() === b.year() && 
      a.month() === b.month() && 
      a.date() === b.date()
    );
  }

  const handleDayClick = (day: Dayjs) => {
    if (isMultipleDates) {
      if (refDates.current && refDates.current.some(d => isSameDay(d, day))) {
        if (refDates.current.length > 1) {
          refDates.current = refDates.current.filter((d) => !isSameDay(d, day));
        }
      } else {
        refDates.current = day ? [...refDates.current, day] : [];
      }
    } else {
      refDates.current = [day];
    }

    setPickedDates(refDates.current);
  };

  function EventToolbar(props: DatePickerToolbarProps<Dayjs>) {
    const handleMultiToggle = () => {
      setIsMultipleDates(!isMultipleDates);
    };

    return (
      <Box
        // Pass the className to the root element to get correct layout
        className={props.className}
        sx={{ display: 'flex', py: 4, alignItems: 'flex-center', justifyContent: 'space-between' }}
      >
        <DatePickerToolbar {...props} />
        <FormGroup>
          <FormControlLabel control={
            <Switch 
              checked={isMultipleDates}
              name="multi-day"
              onChange={handleMultiToggle}
            />} label="Multi-day" />
        </FormGroup>
      </Box>
    );
  }

  return (
      <StaticDatePicker
        value={props.currentDate}
        orientation={props.orientation}
        slots={{
          day: (props) => {
            const isSelected = refDates.current.some(day => isSameDay(day, props.day));
            return <PickersDay {...props} selected={isSelected} onClick={() => handleDayClick(props.day)}/>;
          },
          toolbar: EventToolbar
        }}
        slotProps={{
          toolbar: {
            toolbarFormat: 
              DisplayDates(refDates.current.map(datetime => datetime.toDate()), "America/Los_Angeles"),
            toolbarPlaceholder: "Event Dates",
            sx: {
              '.MuiTypography-overline': {
                display: "none"
              },
              '.MuiDatePickerToolbar-title': {
                ...subtitleStyles
              }
            }
          },
          actionBar: {
            actions: [] 
          }
        }}
      />
  );
}
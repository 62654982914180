import { 
  ReactElement, 
  useState 
} from "react";

import { NavLink } from "react-router-dom";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Button from "@mui/material-next/Button";
import IconButton from '@mui/material-next/IconButton';

import { useData } from "../DataProvider";
import { cardStyles } from "../styles/styles";
import League from '../types/League';

import LeagueActionBar from "./LeagueActionBar";
import LeagueHeader from "./LeagueHeader";

export default function LeagueCard(league:League): ReactElement {
  const { deleteLeague, isOrgAdmin } = useData();

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const deleteThisLeague = (id: string) => {
    console.log(`Deleting event ${id}`);
    deleteLeague(id);
    setDeleteConfirm(false);
  };


  return (
    <Card sx={cardStyles.card}>
      <CardActionArea component={NavLink} to={`/league/${league.itemId}`}>
        <LeagueHeader leagueId={league.itemId}/>
      </CardActionArea>
      <CardActions>
        <LeagueActionBar 
          leagueId={league.itemId} 
        />
      </CardActions>
      {isOrgAdmin(league.orgId) && (
        <CardActions>
            <Stack direction="row" spacing={1}>
            <IconButton
              component={NavLink}
              to={`/league-edit/${league.itemId}`}
              state={{ leagueId: league.itemId }}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => setDeleteConfirm(true)}
            >
              <DeleteIcon />
            </IconButton>
            {deleteConfirm && (
              <span>
                <Button
                  size="small"
                  onClick={() => deleteThisLeague(league.itemId)}
                >
                  Confirm
                </Button>
                <Button size="small" onClick={() => setDeleteConfirm(false)}>
                  Cancel
                </Button>
              </span>
            )}
            </Stack>
        </CardActions>
      )}
    </Card>
  );
}
import { 
  ReactElement, 
  useEffect,
  useState
} from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import {
  sectionStyles, 
} from "../styles/styles";
import Competition from "../types/Competition";

interface CompetitionEditorProps {
  onChange: (competitionChange: Partial<Competition>) => void;
  competition: Competition;
} 

export default function CompetitionEditor(props: CompetitionEditorProps): ReactElement {
  const [competition, setCompetition] = useState<Competition>(props.competition);

  // Todo handle loading errors and waiting to load hourglass
  useEffect(() => {

  }, [competition]);

  const handleOnChange = (updatedCompetition: Partial<Competition>) => {
    setCompetition({...competition, ...updatedCompetition});
    props.onChange(updatedCompetition);
  };

  if (competition) {
    return (
      <Box sx={sectionStyles}>
        <TextField
          id="name"
          label="Name"
          value={competition.name ? competition.name : ""}
          onChange={(e) => handleOnChange({name: e.target.value})} />
      </Box>
    );
  } else {
    return <> </>;
  }
}

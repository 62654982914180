import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import Papa from "papaparse";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';

import { useData } from "../DataProvider";
import { 
  CompHandicap, 
  CompScoring,
} from "../types/Competition";
import Score from "../types/Score";
import { generateFirestoreId } from "../utils/utils";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type Scores = {
  data: Score[]
}

export default function SeasonStandingCsvUpload() : ReactElement {
  const { adminOrgs, isOrgAdmin, addStandingToSeason } = useData();

  const [csvFile, setCsvFile] = useState<string>(""); 
  const [scores, setScores] = useState<Scores>({ data: [] });

  useEffect(() => {
    if (csvFile !== "") {
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (results: Scores) => {
          setScores(results);
        }
      });
    }
  }, [csvFile]);

  useEffect(() => {
    if (scores.data.length > 0) {


      const standing = {
        competition: {
          name: "Meadows Eclectic - Net",
          scoring: CompScoring.Eclectic,
          handicap: CompHandicap.Net,
          itemId: generateFirestoreId()
        },
        courseId: "1yeuzwBqMruA0QK4aDLc",
        courseName: "Meadows Golf Course",
        name: "Meadows Eclectic - Net",
        scores: scores.data
      }
      addStandingToSeason("FdU26gNcxrqoxlZUtNCy", standing).catch (error => {
        console.log(error, "playerId: " + standing.name);
      });
    }

  }, [scores, addStandingToSeason]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === "string") {
          setCsvFile(contents);
        }
      };
      reader.readAsText(file);
    }
  };

  if (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) {
    return (
      <Grid item container justifyContent="center" >  
        <Grid item xs={4}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}>
          Upload Standings for a Season
          <VisuallyHiddenInput 
            type="file" 
            accept=".csv"
            onChange={handleFileUpload}       
          />      
        </Button>
        </Grid>   
      </Grid>
    );
  } else {
    return <></>;
  }
}
import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { doc } from "firebase/firestore";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material-next/Button";

import { useApp } from "../AppProvider";
import AttendeeList from "../components/EventAttendees";
import EventEditor from "../components/EventEditor";
import EventHeader from "../components/EventHeader";
import EventRegBar from "../components/EventRegBar";
import EventRegEditor from "../components/EventRegEditor";
import Hourglass from "../components/Hourglass";
import TeeSheets from "../components/TeeSheets";
import { useData } from "../DataProvider"; 
import { 
  dividerStyles, 
  sectionStyles, 
 } from "../styles/styles";
import Event from "../types/Event";
import { 
  DisplayDate,
  DisplayDates 
} from "../utils/utils";
import { IsEventUpcoming } from "../utils/utils";
import { CurrentDateTime } from "../utils/utils";

interface EventPageProps {
  isEditMode?: boolean;
}

export default function EventPage(props: EventPageProps): ReactElement {
  
  const { setPageName, setActions } = useApp();
  const { eventsCol, isLeagueAdmin, isOrgAdmin, updateEvent } = useData();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(props.isEditMode ? props.isEditMode : false);

  const [event, isEventLoading, eventLoadingError] = useDocumentData<Event>(doc(eventsCol, eventId));

  useEffect(() => {
    if (!isEventLoading) {
      if (eventLoadingError) {
      console.log("Event not found");
      }
      if (event) {
        const dateText = event.datetimes ? 
          DisplayDates(event.datetimes.map(datetime => datetime.toDate()), event.timezone) :
          DisplayDate(event.datetime.toDate(), event.timezone, "M/D");

        setPageName(dateText + " " + event.name);
        if (!isEditMode && (isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId))) {
          setActions([{ 
            name: "Edit", 
            handleClick: handleEditClick, 
            icon: EditIcon }])
        }
      }
    }
  }, [event, isEventLoading, eventLoadingError, isEditMode, setActions, setPageName, isOrgAdmin, isLeagueAdmin]);

  /*
  const currentSeason = ():Season | null => {
    if (seasons && event && event.leagueId) {
      const currentTime = event.days[0].datetime;
      const season = 
        seasons.find(season => (season.leagueId == event.leagueId) && currentTime > season.startTime && currentTime <= season.endTime);
      return (season ? season : null);
    } else {
      return null;
    }
  }
  */
  
  const handleSubmit = (event: Event) => {
    if (event) {
      updateEvent(event.itemId, event);
    };
    navigate(-1);
  }

  const handleCancel = () => {
    navigate("/event/" + eventId, { replace: true });
    setIsEditMode(false);
  }

  const handleEditClick = () => {
    navigate("/event-edit/" + eventId, { replace: true });
    setIsEditMode(true);
  }

  const handleCreateTeeSheet = () => {
    if (event) {
    try {
      updateEvent(event.itemId, {isTeeSheetCreated: true});
    } catch (error) {
      console.log("Error setting isTeeSheetCreated to true: " + error);
    }
    } else {
      console.log("Error setting isTeeSheetCreated to true: event is null");
    }
  };

  // TODO: This is a duplicate of the function in EventCard.tsx
  const isSignupOpen = () => {
    if (event) {
      const currentDateTime = CurrentDateTime();
      return (currentDateTime > event.signupOpensAt && currentDateTime < event.signupClosesAt);
    } else {
      return false;
    }
  }

  const Attendees = (): ReactElement => {
    if (event) {
      if (event.isGolf && event.isTeeSheetCreated) {
        return(<TeeSheets eventId={event.itemId} />);
      } else {
        return (
          <>
            <AttendeeList event={event} isEditable={isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId)} />
            {(isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId)) && event.isGolf && 
                <Button type="button" key="formEdit" size="medium" variant="filled" color="secondary" onClick={() => handleCreateTeeSheet()}>
                  Create Tee Sheet
                </Button>
            }
          </>
        );
      }
    } else {
      return (<></>);
    }
  };

  const EventDetails = (): ReactElement => {
    if (event) {
      return (
        <Grid container width="100%" justifyContent="flex-start" spacing={2} sx={sectionStyles}>
          <Grid item container xs={12} sm={6} > 
            <Grid item xs={12}>
              <EventHeader event={event} />
            </Grid>
            <Grid item xs={12}>
              <EventRegBar 
                event={event}
                isSignupOpen={isSignupOpen()} 
                isFull={event?.registrationCount ? (event.registrationCount>= event.maxPlayers) : false}
              />
            </Grid>
            {event.isGolf && IsEventUpcoming(event.datetime) && !event.isTeeSheetCreated &&
              <Grid item xs={12}>
                <EventRegEditor 
                  eventId={event.itemId} 
                  isMemberMember={event.isMemberMember ? true : false}
                  isMemberGuest={event.isMemberGuest ? true : false} />
              </Grid>}
          </Grid>
          <Grid item xs={12} sm={6} alignItems="flex-start">
            <Stack direction="column" alignItems="flex-start" justifyContent="flex-end">
              <Divider sx={{...dividerStyles, display: {sm: 'none'}}}/>
              <Attendees />
            </Stack>
          </Grid>
        </Grid>
      );
    } else {
      return (<></>);
    }
  };

  if (event && !isEventLoading) {
    if (isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId)) {
      return (
        <Box sx={sectionStyles}>
          {isEditMode ? 
            <EventEditor 
              event={event} 
              isNewEvent={false} 
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            /> : 
            <EventDetails />
          }
        </Box>
      );
    } else {
      return (
        <EventDetails />
      );
    }

  } else {
    return (<Hourglass />);
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&
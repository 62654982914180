import { 
  ReactElement,
} from  "react";

import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { useData } from "../DataProvider";
import { subtitleStyles } from "../styles/styles";
import Course from "../types/Course";
import EventDay from "../types/EventDay";
import { 
  DisplayDate,
  ToDayJS 
} from "../utils/utils";

export interface EventTimeEditorProps {
  days: EventDay[], 
  defaultTime?: Dayjs,
  timezone?: string, 
  isGolfEvent?: boolean, 
  onChange: (newDays: EventDay[]) => void;
}

export default function EventTimeEditor (props: EventTimeEditorProps): ReactElement {
  const {
    courses,
  } = useData();

  const days = props.days;
  const defaultTime = props.defaultTime ? props.defaultTime : dayjs("2000-01-01T09:00:00");

  const timezone = props.timezone ? props.timezone : "America/Los_Angeles";
  const isGolfEvent = props.isGolfEvent ? props.isGolfEvent : true;
  const onChange = props.onChange;
  
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs")); 
  const isMed = useMediaQuery(theme.breakpoints.only("md"));
  const isSmallScreen = isXS || isMed;

  if (days.length === 0) {
    return (
      <Stack>
        <Typography sx={{...subtitleStyles}}>Start Time</Typography>
        <TimePicker
          value={defaultTime} />
      </Stack>
    );
  } else {
    return (
      <Stack sx={{px: 6, py: 4}}>
        <Typography sx={{...subtitleStyles}}>Daily Start Times</Typography>
        {days.map((day, i) => (
          <Grid key={i} container>
            <Grid item xs={4} >
              <TimePicker
                label={DisplayDate(day.datetime.toDate(), timezone, isSmallScreen ? "M/D/YY" : "MMM DD, YYYY")}
                value={ToDayJS(days[i].datetime, timezone)} 
                onChange={(target) => {
                  if (target != null) {
                    const newDays = [...days];
                    newDays[i].datetime = Timestamp.fromDate(target.toDate());
                    onChange(newDays);
                  }
                }}
              />
            </Grid>
            <Grid item xs={5}>
              {(day.isGolf || isGolfEvent) && courses && courses.length > 0 && (
                <TextField
                  id="location"
                  autoFocus={false}
                  select
                  label="Location"
                  aria-label="location"
                  aria-required="false"
                  value={days[i].courseId ? days[i].courseId : ""}
                  fullWidth
                  onChange={({ target }) => {
                    const newDays = [...days];
                    newDays[i].courseId = target.value;
                    onChange(newDays);
                  }}
                >
                {courses?.map((course: Course) => (
                  <MenuItem key={course.itemId} value={course.itemId}>
                    {isSmallScreen ? course.shortName : course.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            </Grid>
            <Grid item xs={3} md={3}>
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Switch
                      checked={days[i].isShotgun ? days[i].isShotgun : false}
                      name="shotgun"
                      onChange={({ target }) => {
                        const newDays = [...days];
                        newDays[i].isShotgun = target.checked;
                        onChange(newDays);
                      }}
                    />
                  } 
                  label="Shotgun" 
                  labelPlacement={isSmallScreen ? "top" : "start"}
                />
              </FormGroup>
            </Grid>
          </Grid>
        ))}
      </Stack>
    );
  }
}
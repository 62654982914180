import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";

export function generateFirestoreId(): string {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  
  let autoId = ''
  
  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(
      Math.floor(Math.random() * CHARS.length)
    );
  }
  return autoId;
}


export function GetFirstAndLastName(fullName: string): [firstName: string, lastName: string] {
  const regex = /\w+\s\w+(?=\s)|\w+/g;

  if (fullName) {
    const matches = fullName.trim().match(regex);
    if (matches) {
      return [matches[0], matches[1]];
    } else {
      return [fullName, ""]
    }
  } else {
    return [fullName, ""];
  }
}

export function GetFirstName (fullName: string): string {
  return GetFirstAndLastName(fullName)[0];
}

export function FormatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return "";
}

export function IsEventUpcoming(startTime: Timestamp) {
  const currentDateTime = CurrentDateTime();
  return (currentDateTime < startTime);
}

export function IsEventInProgress(startTime: Timestamp, endTime: Timestamp) {
  const currentDateTime = CurrentDateTime();
  return ((currentDateTime > startTime) && (currentDateTime < endTime));
}

export function IsEventOver(endTime: Timestamp) {
  const currentDateTime = CurrentDateTime();
  return (currentDateTime > endTime);
}

export function IsEventSignupOpen(signupOpensAt: Timestamp, signupClosesAt: Timestamp) {
  const currentDateTime = CurrentDateTime();
  return ((currentDateTime < signupClosesAt) && (currentDateTime > signupOpensAt));
}

export function IsEventSignupClosed(signupClosesAt: Timestamp) {
  const currentDateTime = CurrentDateTime();
  return (currentDateTime > signupClosesAt);
}

export function DisplayDate(date: Date, tz: string, format?: string): string {
  dayjs.tz.setDefault(tz ? tz : "America/Los_Angeles");

  return dayjs.tz(date.toLocaleString("default", { timeZone: tz }), tz).format(format ? format : 'MMM D, YYYY');
  // timezone is number of hours relative to UTC. SO PST would be -8
  //return new Date(/*(*/datetime.seconds /*+ (timezone * 60 * 60))*/ * 1000)
  //  .toLocaleDateString('en-US');
}

export function DisplayDates(dates: Date[], tz: string, format?: string, isTwoLine?: boolean): string {
  const sortedDates = dates.sort((a, b) => a.valueOf()- b.valueOf());
  const startDate = sortedDates[0];
  const endDate = sortedDates[sortedDates.length-1];

  if (dates.length === 0) {
    return "";
  } else if (dates.length === 1) {
    return DisplayDate(startDate, tz, format);
  } else {
    if (startDate.getFullYear() === endDate.getFullYear()) {
      if (startDate.getMonth() === endDate.getMonth()) {
        return (
          startDate.toLocaleString("default", { month: "short" }) + " " + 
          startDate.getDate().toString() + "-" + 
          endDate.getDate().toString() + ", " + 
          endDate.getFullYear().toString());
      } else {
        if (isTwoLine) {
          return (
            startDate.toLocaleString("default", { month: "short" }) + " " + 
            endDate.toLocaleString("default", { month: "short" }) + "\n" + 
            startDate.getDate().toString() + " " + 
            endDate.getDate().toString() + ", " + 
            endDate.getFullYear().toString());
        } else {
          return (
            startDate.toLocaleString("default", { month: "short" }) + " " + 
            startDate.getDate().toString() + "-" + 
            endDate.toLocaleString("default", { month: "short" }) + " " + 
            endDate.getDate().toString() + ", " + 
            endDate.getFullYear().toString());
        }
      }
    } else {
      // TODO: Handle multi-year events.
      return (
        startDate.toLocaleString("default", { month: "short" }) + " " + 
        startDate.getDate().toString() + ", " + 
        startDate.getFullYear().toString() + " - " + 
        endDate.toLocaleString("default", { month: "short" }) + " " + 
        endDate.getDate().toString() + ", " + 
        endDate.getFullYear().toString());
    } 
    // timezone is number of hours relative to UTC. SO PST would be -8
    //return new Date(/*(*/datetime.seconds /*+ (timezone * 60 * 60))*/ * 1000)
    //  .toLocaleDateString('en-US');    
  }
}

export function CombineDateJSTimeJS(date: Dayjs, time: Dayjs): Timestamp {
  const combined = date.set('hour', time.hour()).set('minute', time.minute());
  return Timestamp.fromDate(combined.toDate());
}

export function DisplayTime(datetime: Timestamp, tz: string, format?: string): string {
  dayjs.tz.setDefault(tz);

  return dayjs.tz(datetime.toDate()).format(format ? format: 'h:mm A'); 
}

export function DisplayDateTime(datetime: Timestamp, tz: string): string {
  return DisplayDate(datetime.toDate(), tz) + " " + DisplayTime(datetime, tz);
}

export function CurrentDateTime(): Timestamp {
  return Timestamp.now();
}

export function CurrentDate(): Timestamp {
  return Timestamp.fromDate(new Date());
}

export function FromDayJS(dayjs: Dayjs | null): Timestamp {
  return (dayjs != null) ? Timestamp.fromDate(dayjs.utc().toDate()) : Timestamp.now();
}

export function ToDayJS(datetime: Timestamp, timezone?: string): Dayjs {

  if ((datetime == null) || (datetime.seconds == null)) {
    datetime = Timestamp.now();
  }

  if (timezone == null) {
    timezone = "America/Los_Angeles";
  } 

  return dayjs.tz(datetime.toDate(), timezone);
}

export function TimezoneList(): {value: number, label: string}[] {
  return [
    {value: -8, label: "PST"},
    {value: -7, label: "PDT"},
    {value: -6, label: "MDT"},
  ];
}
import { 
  ReactElement, 
  useEffect,
  useState, 
} from "react";

import { 
  doc, 
  DocumentReference, 
} from "firebase/firestore";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { NavLink } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material-next/Button";

import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import { 
  listStyles 
} from "../styles/styles";
import Event from "../types/Event";
import Registration from "../types/Registration";
import { 
  DisplayDate,
  DisplayDates,
  IsEventOver, 
  IsEventUpcoming 
} from "../utils/utils";

import PartnerSelector from "./PartnerSelector";

interface ConfirmRegisterDialogProps {
  isOpen: boolean;
  isFull: boolean;
  eventTitle: string;
  onCancel: () => void;
  onConfirm: () => void;
}

function ConfirmRegisterDialog(props: ConfirmRegisterDialogProps): ReactElement {
  const { eventTitle, isOpen, isFull, onConfirm, onCancel, ...other } = props;
  return (
    <Dialog
      open={isOpen}
      {...other}
    >
      <DialogTitle>{eventTitle}</DialogTitle>
      <DialogContent>
        {isFull ? 
          "Be added to the waitlist?" : "Confirm you are attending."}
      </DialogContent>
      <DialogActions>
        <Button variant="filled" size="small" color="primary" onClick={onConfirm}>
          {isFull ? "Confirm Waitlist" : "Confirm Signup"}
        </Button>
        <Button variant="text" size="small" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface EventRegBarProps {
  event: Event;
  isSignupOpen: boolean;
  isFull: boolean;
}

export default function EventRegBar(props: EventRegBarProps): ReactElement {
  const event = props.event;
  const { addEventRegistration, userProfile } = useData();
  const [isRegistering, setIsRegistering] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);

  const [registration, isRegistrationLoading, isRegistrationLoadingError] = 
    useDocumentData<Registration>(doc(firestore, "event/" + event.itemId + "/reg/" + userProfile?.itemId) as DocumentReference<Registration>);

  useEffect(() => {
    if (event.isMemberMember && registration) {
      console.log("registration: " + registration.isRegistered);
    }
    if (isRegistrationLoadingError) {
        console.log("Error loading registration");
    }

  }, [event.isMemberMember, registration, isRegistrationLoadingError]);

  const handleCancelRegistration = () => {
    if (registration?.playerId) {
      try {
        addEventRegistration(event.itemId, false);
        setCancelConfirm(false);
      } catch (error) {
        alert(error);
      }
    }
  }

  const handleRegistration = () => {
    try {
      addEventRegistration(event.itemId, true);
    } catch (error) {
      alert(error);
    }
    setIsRegistering(false);
  };

  const styledDate = (): string => {
    if (event) {
      console.log(event.datetimes);
      return (
        event.datetimes && event.datetimes.length > 1 ? 
          DisplayDates(event.datetimes.map(datetime => datetime.toDate()), event.timezone) :
          DisplayDate(event.datetime.toDate(), event.timezone));
    } else {
      return ("");
    }
  }

  const styledTitle = (): string => {
    const name = event.name.length > 0 ? " - " + event.name : "";
    return (
      styledDate() + " " + name
    )
  }

  if (!userProfile || isRegistrationLoading || isRegistrationLoadingError) {
    return (<> </>);
  } else {
    if (IsEventUpcoming(event.datetime)) {
      if (registration && registration.isRegistered) {
        return (
          <Box>
            <Grid container width="100%" spacing={2}>
              {event.isMemberMember && (
                <Grid item xs={12} sx={listStyles}>
                  <PartnerSelector isMemberMember={true} registration={registration} />
                </Grid>
              )}
              {event.isMemberGuest && (
                <Grid item xs={12} sx={listStyles}>
                  <PartnerSelector isMemberGuest={true} registration={registration} />
                </Grid>
              )}
              <Grid item xs={12} >
                <Alert severity="success">
                  You are signed up.
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  {!cancelConfirm && <Button variant="outlined" startIcon={<CancelIcon />} color="secondary" size="small" onClick={() => setCancelConfirm(true)}>
                    Cancel Signup
                  </Button>}
                  {cancelConfirm && (
                    <Stack direction="row" spacing={1}>
                      <Button variant="text" size="small" color="secondary" onClick={() => handleCancelRegistration()}>
                        Confirm Cancel
                      </Button>
                      <Button variant="text" size="small" color="secondary" onClick={() => setCancelConfirm(false)}>
                        Undo Cancel
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Grid>

            </Grid>
          </Box>
        );
      } else if (isRegistering) {
        return (
          <ConfirmRegisterDialog 
            eventTitle={styledTitle()}
            isFull={props.isFull}
            isOpen={isRegistering} 
            onCancel={() => setIsRegistering(false)}
            onConfirm={() => handleRegistration()}
          />
        );
      } else if (props.isSignupOpen) {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} >
              <Alert severity="warning">
                {props.isFull ? "Event is full." : "You are not signed up."}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Button variant="filled" size="small" onClick={() => setIsRegistering(true)}>{props.isFull ? "Join Waitlist" : "Sign up"}</Button>
              </Stack>
            </Grid>

          </Grid>);
      } else {
        return (<></>);
      }
    } else if (event.isGolf){
      if (IsEventOver(event.datetime)) {
        return (
          <Button variant="outlined" size="small" component={NavLink} to="/event" state={{eventId: event.itemId, isNewEvent: false}} aria-label="view event details">
            View Results
          </Button>);
      } else {
        return (
          <Button variant="outlined" size="small" component={NavLink} to="/event" state={{eventId: event.itemId, isNewEvent: false}} aria-label="view event details">
            View Tee Times
          </Button>);
      }
    } else {
      return (<></>);
    }
  }  
}
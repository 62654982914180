import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { 
  getDownloadURL, 
  ref 
} from "firebase/storage";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {  
  useNavigate,
  useParams,
} from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from "@mui/material-next/Button";

import { useApp } from "../AppProvider";
import { useAuth } from "../AuthProvider";
import Hourglass from "../components/Hourglass";
import PersonEditor from "../components/PersonEditor";
import { useData } from "../DataProvider";
import { cloudFunctions } from "../firebase";
import { 
  labelStyles, 
  sectionStyles, 
  subtitleStyles, 
  titleStyles 
} from "../styles/styles";
import UserProfile from "../types/UserProfile";
import { 
  DisplayDate, 
} from "../utils/utils";

interface PersonPageProps {
  isEditMode? : boolean;
}

export default function PersonPage(props: PersonPageProps): ReactElement {
  const { setActions, setPageName } = useApp();
  const { isGod, isRevealingGodsSecrets, isLoggedIn, imagesRef } = useAuth();
  const { adminOrgs, isOrgAdmin, updateUserProfile, userProfile, userProfilesCol } = useData();

  const { profileId } = useParams() as { profileId: string };
  // if no profileId is passed in, show the logged-in user's profile
  const activeProfileId = profileId ? profileId : userProfile?.itemId;
  const navigate = useNavigate();

  // The profile that is being edited is the active profile
  const [activeProfilePic, setActiveProfilePic] = useState("");
  const [isEditMode, setIsEditMode] = useState(props.isEditMode ? props.isEditMode : false);

  const [
    activeProfile, 
    isProfileLoading, 
    profileLoadingError] 
    = useDocumentData<UserProfile>(doc(userProfilesCol, activeProfileId));

  useEffect(() => {
    if (activeProfile) {
      if (activeProfile.picture) {
        getDownloadURL(ref(imagesRef, activeProfile.picture))
          .then((url) => {
            setActiveProfilePic(url);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setPageName(activeProfile.name);
      if (!isEditMode && (isGod || (activeProfile && userProfile && activeProfile.itemId === userProfile.itemId))) {
        setActions( [{ name: "Edit", handleClick: handleEditClick, icon: EditIcon }]);
      }
      console.log(activeProfile.name)
    }
  }, [activeProfile, imagesRef, isEditMode, isGod, isProfileLoading, profileLoadingError, setActions, setPageName, userProfile]);

  const handleEditClick = () => {
    navigate("/profile-edit/" + activeProfileId, { replace: true });
    setIsEditMode(true);
  }

  const handleCancelEdit = () => {
    navigate("/profile/" + activeProfileId, { replace: true });
    setIsEditMode(false);
  }

  const handleSubmitEdit = (updatedProfile: UserProfile, uploadPic: File | null | undefined) => {
    if (activeProfile) {
      console.log("Submit: ", updatedProfile.picture, updatedProfile.pictureName, uploadPic);
      updateUserProfile(activeProfile.itemId, updatedProfile, uploadPic)
    };
    setIsEditMode(false);
  }

  // This call will only work if the user is already a god.
  const handleDeify = () => {
    if (activeProfile) {
      const deifyUser = httpsCallable(cloudFunctions, 'makeUserAGod');
      deifyUser({email: activeProfile?.email})
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("No active profile");
    }
  };

  const ProfileView = (props: {profile: UserProfile}): ReactElement => {
    const profile = props.profile;

    return (
      <Box sx={sectionStyles}>
        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={{ xs: 8, md: 12 }} sx={sectionStyles}>
          <Grid item xs={2} sm={4}>
            {activeProfilePic &&
              <Box component="img" src={activeProfilePic} alt="profile picture" sx={{ width: "100%" }} />
            }
          </Grid>
          <Grid container item xs={10} sm={8}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={titleStyles}>
                {profile.name}
              </Typography>
            </Grid>
            {profile.ghin ?
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6} md={8}>
                  <Typography variant="h6" component="span" sx={labelStyles}>
                    GHIN:
                  </Typography>
                  <Typography variant="h6" component="span" sx={subtitleStyles}>
                    {profile.ghin}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6" component="span" sx={labelStyles}>
                    Index:
                  </Typography>
                  <Typography variant="h6" component="span" sx={subtitleStyles}>
                    {profile.index}
                  </Typography>
                </Grid>
              </Grid>
            : <></>}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" sx={subtitleStyles}>
                {profile.email}
              </Typography>
            </Grid>
            {profile.phone && profile.phone.length > 1 &&
              <Grid item xs={12} sm={6} md={8}>
                <Typography variant="h6" sx={subtitleStyles}>
                  {profile.phone}
                </Typography>
              </Grid>
            }
            {profile.birthday &&
              <Grid item xs={12}>
                <Typography variant="body1" component="span" sx={labelStyles}>
                  Birthday: 
                </Typography>
                <Typography variant="body1" component="span" sx={subtitleStyles}>
                  {DisplayDate(profile.birthday.toDate(),"America/Los_Angeles", "MMMM D")}
                </Typography>
              </Grid>
            }
            {profile.gender &&
              <Grid item xs={12}>
                <Typography variant="h6" component="span" sx={labelStyles}>
                  Gender:
                </Typography>
                <Typography variant="h6" component="span" sx={subtitleStyles}>
                  {profile.gender}
                </Typography>
              </Grid>
            }
            {profile.mailingAddress &&
              <Grid item xs={12}>
                <Typography variant="h6" sx={labelStyles}>
                  Mailing Address
                </Typography>
                <Typography variant="h6" component="span" sx={subtitleStyles}>
                  {profile.mailingAddress}
                </Typography>
              </Grid>
            }
            {profile.localAddress &&
              <Grid item xs={12}>
                <Typography variant="h6" sx={labelStyles}>
                  Local Address
                </Typography>
                <Typography variant="h6" component="span" sx={subtitleStyles}>
                  {profile.localAddress}
                </Typography>
              </Grid>
            }
            {isRevealingGodsSecrets && profile.isTest &&
              <Grid item xs={12}>
                <Typography variant="h6" sx={labelStyles}>
                  Test Profile
                </Typography>
              </Grid>
            }
            {isRevealingGodsSecrets && 
              <Grid item xs={12}>
                <Typography variant="h6" component="span" sx={labelStyles}>
                  User Id
                </Typography>
                <Typography variant="h6" component="span" sx={subtitleStyles}>
                  {profile.itemId}
                </Typography>
              </Grid>
            }
          </Grid>
        </Grid>
        {isRevealingGodsSecrets &&
          <Button type="button" size="medium" variant="outlined" color="secondary" onClick={handleDeify}>
            Deify
          </Button>}
      </Box>
    );
  }

  if (activeProfile) {
    if ((adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) || 
      (activeProfile && userProfile && activeProfile.itemId === userProfile.itemId)) {
      return (
        <Box sx={sectionStyles}>
          {isEditMode ?
            <PersonEditor profile={activeProfile} isNewProfile={false} onCancel={handleCancelEdit} onSubmit={handleSubmitEdit}/> :
            <ProfileView profile={activeProfile}/>}
        </Box>
      );
    } else { 
      return (
        <ProfileView profile={activeProfile}/>
      ); 
    }
  } else if (isLoggedIn) {
    return (<Hourglass />);
  } else {
    return (<Container />);
  }
}
